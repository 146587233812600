<template>
  <div class="px-6 pb-2 pt-6">
    <v-dialog
      v-model="dialog"
      max-width="800px"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <vs-button class="w-full" @click="dialog = true">Adauga Task</vs-button>
      </template>
      <v-card>
        <v-card-title>Adauga un task nou</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <form @submit.prevent="addTask">

            <div class="vx-row">
              <div class="vx-col w-full">
                <v-text-field
                  v-model="taskLocal.title"
                  label="Titlu"
                ></v-text-field>
                <error-messages :errors="errors.title"/>

                <v-textarea
                  v-model="taskLocal.task"
                  label="Scrie ce trebuie sa faci in continuare"
                ></v-textarea>
                <error-messages :errors="errors.task"/>

                <v-autocomplete
                  v-model="taskLocal.users_ids"
                  :items="agents"
                  chips
                  clearable
                  deletable-chips
                  item-text="full_name"
                  item-value="id"
                  label="Responsabil"
                  multiple
                  single-line
                  small-chips
                ></v-autocomplete>
                <error-messages :errors="errors.users_ids"/>

                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="taskLocal.date"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Data"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="taskLocal.date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                  <error-messages :errors="errors.date"/>
                </v-menu>

                <v-select
                  v-model="taskLocal.category_id"
                  :items="taskTags"
                  item-text="name"
                  item-value="id"
                  label="Tipul de task"
                ></v-select>
                <error-messages :errors="errors.category_id"/>

                <v-checkbox v-model="repeaterCheckbox">
                  <template v-slot:label>
                    <div>
                      <label>
                        Task-ul se repeta dupa
                        <input v-model="taskLocal.repeater" class="w-8 bg-grey-light text-center" placeholder="30"
                               type="text">
                        zile
                      </label>
                      <error-messages :errors="errors.repeater"/>
                    </div>
                  </template>
                </v-checkbox>

<!--                <v-text-field v-model="taskLocal.client_id" class="" label="Client" value="2"/>-->
<!--                <error-messages :errors="errors.client_id"/>-->

              </div>
            </div>

          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addTask"
          >
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {ErrorMessages},
  data () {
    return {
      dialog: false,
      menu: false,
      repeaterCheckbox: false,
      errors: {},

      taskLocal: {
        title: '',
        category_id: '',
        client_id: '2',
        date: '',
        users_ids: '',
        repeater: '',
        task: ''
      }
    }
  },
  computed: {
    taskTags () {
      return this.$store.state.todo.taskTags
    },
    agents () {
      return this.$store.getters['taskManagement/loadAgents']
    }
  },
  methods: {
    clearFields () {
      Object.assign(this.taskLocal, {
        title: '',
        category_id: '',
        client_id: '2',
        date: '',
        users_ids: '',
        repeater: '',
        task: ''
      })
    },
    addTask () {
      this.$store.dispatch('taskManagement/addTask', Object.assign({}, this.taskLocal))
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Task-ul a fost adaugat cu succes!',
            color: 'success'
          })
          this.dialog = false
          this.clearFields()
        })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  },
  created () {
    this.$store.dispatch('taskManagement/loadAgents')
  }
}
</script>
