<template>
<!--    <vs-prompt-->
<!--        title="Editeaza Task-ul"-->
<!--        accept-text= "Salveaza"-->
<!--        button-cancel = "border"-->
<!--        @accept="submitTodo"-->
<!--        @cancel="displayPrompt === false"-->
<!--        :active.sync="activePrompt">-->
<!--        <div>-->
<!--            <form>-->
<!--                <div class="vx-row">-->
<!--                    <div class="vx-col w-1/6 self-center">-->
<!--                        <vs-checkbox v-model="taskLocal.is_completed" class="w-8"></vs-checkbox>-->
<!--                    </div>-->

<!--                  <div class="vx-col ml-auto flex">-->
<!--                    <vs-dropdown class="cursor-pointer flex" vs-custom-content>-->

<!--                      <feather-icon icon="TagIcon" svgClasses="h-5 w-5" @click.prevent></feather-icon>-->
<!--                      &lt;!&ndash; <vs-button radius color="success" class="primary-button" type="flat" iconPack="feather" icon="icon-tag" @click.prevent></vs-button> &ndash;&gt;-->

<!--                      <vs-dropdown-menu style="z-index: 200001">-->
<!--                        <vs-dropdown-item @click.stop v-for="(tag, index) in taskTags" :key="index">-->
<!--                          <vs-checkbox :vs-value="tag.id" v-model="taskLocal.tags">{{ tag.name }}</vs-checkbox>-->
<!--                        </vs-dropdown-item>-->
<!--                      </vs-dropdown-menu>-->
<!--                    </vs-dropdown>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="vx-row">-->
<!--                    <div class="vx-col w-full">-->
<!--                        <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="taskLocal.title" />-->
<!--                        <vs-textarea rows="5" label="Add description" v-model="taskLocal.desc" />-->
<!--                    </div>-->
<!--                </div>-->

<!--            </form>-->
<!--        </div>-->
<!--    </vs-prompt>-->

  <v-dialog
    v-model="displayPrompt"
    max-width="800px"
    scrollable
    class="edit-modal-asd"
  >
    <template v-slot:activator="{ on, attrs }">
      <vs-button class="w-full" @click="activePrompt = true">Adauga Task</vs-button>
    </template>
    <v-card>
      <v-card-title>Adauga un task nou</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <form @submit.prevent="addTask">

          <div class="vx-row">
            <div class="vx-col w-full">
              <v-text-field
                v-model="taskLocal.title"
                label="Titlu"
              ></v-text-field>
              <error-messages :errors="errors.title"/>

              <v-textarea
                v-model="taskLocal.task"
                label="Scrie ce trebuie sa faci in continuare"
              ></v-textarea>
              <error-messages :errors="errors.task"/>

              <v-autocomplete
                v-model="taskLocal.users_ids"
                :items="agents"
                chips
                clearable
                deletable-chips
                item-text="full_name"
                item-value="id"
                label="Responsabil"
                multiple
                single-line
                small-chips
              ></v-autocomplete>
              <error-messages :errors="errors.users_ids"/>

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="taskLocal.date"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Data"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="taskLocal.date"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
                <error-messages :errors="errors.date"/>
              </v-menu>

              <v-select
                v-model="taskLocal.category_id"
                :items="taskTags"
                item-text="name"
                item-value="id"
                label="Tipul de task"
              ></v-select>
              <error-messages :errors="errors.category_id"/>

              <v-checkbox v-model="repeaterCheckbox" >
                <template v-slot:label>
                  <div>
                    <label>
                      Task-ul se repeta dupa
                      <input v-model="taskLocal.repeater" class="w-8 bg-grey-light text-center" placeholder="30"
                             type="text">
                      zile
                    </label>
                    <error-messages :errors="errors.repeater"/>
                  </div>
                </template>
              </v-checkbox>

              <!--                <v-text-field v-model="taskLocal.client_id" class="" label="Client" value="2"/>-->
              <!--                <error-messages :errors="errors.client_id"/>-->

            </div>
          </div>

        </form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          depressed
          text
          @click="activePrompt = false"
        >
          Close
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="primary-color"
          text
          @click="updateTask"
        >
          Save
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
export default {
  components: {ErrorMessages},
  props: {
    displayPrompt: {
      type: Boolean,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      taskLocal: Object.assign({}, this.$store.getters['taskManagement/getTask'](this.taskId)),
      errors: {},
      menu: false,
      repeaterCheckbox: false

    }
  },
  computed: {
    activePrompt: {
      get () {
        return this.displayPrompt
      },
      set (value) {
        this.$emit('hideDisplayPrompt', value)
      }
    },
    taskTags () {
      return this.$store.state.todo.taskTags
    },
    agents () {
      return this.$store.getters['taskManagement/loadAgents']
    }
  },
  methods: {
    updateTask () {
      this.$store.dispatch('taskManagement/updateTask', this.taskLocal)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Task-ul a fost modificat cu succes!',
            color: 'success'
          })
          this.activePrompt = false
        })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  }
}
</script>
