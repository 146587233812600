<template>
    <div @click="displayPrompt" class="px-4 py-4 list-item-component">
        <div class="vx-row">
            <div class="vx-col w-full sm:w-5/6 flex sm:items-center sm:flex-row flex-col">
                <div class="flex items-center lg:w-4/12">
                    <vs-checkbox v-model="isCompleted" :disabled="isCompleted" class="w-8 m-0 vs-checkbox-small" @click.stop />
                    <h6 class="todo-title font-semibold" :class="{'line-through': taskLocal.is_completed}">{{ taskLocal.title }}</h6>
                </div>

                <div class="todo-tags sm:ml-2 sm:my-0 my-2 flex lg:w-2/12">
                    <vs-chip>
                        <div class="h-2 w-2 rounded-full mr-1" :style="`background:${taskLocal.category.color}`"></div>
                        <span>{{ taskLocal.category.name }}</span>
                    </vs-chip>
                </div>

              <div class="todo-tags sm:ml-2 sm:my-0 my-2 flex lg:w-4/12" v-if="taskLocal.assigned_agents">
                <vs-chip v-for="(agent, index) in taskLocal.assigned_agents" :key="index">
                  <span>{{ agent.full_name }}</span>
                </vs-chip>
              </div>

              <div class="todo-tags sm:ml-2 sm:my-0 my-2 flex justify-end w-24 lg:w-2/12">
                <vs-chip v-if="taskLocal.is_overdue">
                  <span class="text-danger font-bold">{{ taskLocal.date_verbose }}</span>
                </vs-chip>
                <vs-chip v-else>
                  <span>{{ taskLocal.date_verbose }}</span>
                </vs-chip>
              </div>

              <div class="todo-tags sm:ml-2 sm:my-0 my-2 flex justify-end w-24 lg:w-2/12" v-if="taskLocal.client">
                <vs-chip>
                  <span>{{ taskLocal.client.client_display_name }}</span>
                </vs-chip>
              </div>

            </div>
        </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mt-2 mb-0 truncate">
            {{ taskLocal.task }}
          </p>
        </div>
        </div>
<!--        <div class="vx-row" v-if="taskLocal.desc">-->
<!--            <div class="vx-col w-full">-->
<!--                <p class="mt-2 truncate" :class="{'line-through': taskLocal.isCompleted}">{{ taskLocal.desc }}</p>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default{
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      taskLocal: this.$store.getters['taskManagement/getTask'](this.taskId)
    }
  },
  computed: {
    isCompleted: {
      get () {
        return this.taskLocal.is_completed
      },
      set (value) {
        this.$store.dispatch('taskManagement/markAsCompleted', Object.assign({}, this.taskLocal, {is_completed: value}))
          .then((response) => {
            this.taskLocal.is_completed = response.data.is_completed
          })
          .catch((error) => { console.error(error) })
      }
    }
    // todoLabelColor () {
    //   return (label) => {
    //     const tags = this.$store.state.todo.taskTags
    //     return tags.find((tag) => {
    //       return tag.id === label
    //     }).color
    //   }
    // },
  },
  methods: {
    toggleIsImportant () {
      this.$store.dispatch('todo/updateTask', Object.assign({}, this.taskLocal, {isImportant: !this.taskLocal.isImportant}))
        .then((response) => {
          this.taskLocal.isImportant = response.data.isImportant
        })
        .catch((error) => { console.error(error) })
    },
    toggleIsStarred () {
      this.$store.dispatch('todo/updateTask', Object.assign({}, this.taskLocal, {isStarred: !this.taskLocal.isStarred}))
        .then((response) => {
          this.taskLocal.isStarred = response.data.isStarred
        })
        .catch((error) => { console.error(error) })
    },
    moveToTrash () {

      this.$store.dispatch('todo/updateTask', Object.assign({}, this.taskLocal, {isTrashed: true}))
        .then((response) => {
          // console.log(response.data);
          this.taskLocal.isTrashed = response.data.isTrashed
          this.$el.style.display = 'none'   // Hides element from DOM
        })
        .catch((error) => { console.error(error) })

      // Un-comment below line if you want to fetch task after task is deleted
      // this.$store.dispatch("todo/fetchTasks", {filter: this.$route.params.filter})
    },
    displayPrompt () {
      this.$emit('showDisplayPrompt', this.taskId)
    }
  }
}
</script>
