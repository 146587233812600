<template>
  <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar v-model="isSidebarActive" :click-not-close="clickNotClose" :hidden-background="clickNotClose"
                class="items-no-padding task-manager-sidebar" parent="#todo-app">

      <todo-add-new/>

      <component :is="scrollbarTag" :key="$vs.rtl" :settings="settings" class="todo-scroll-area">
        <todo-filters @closeSidebar="toggleTodoSidebar"></todo-filters>
      </component>

    </vs-sidebar>
    <div :class="{'sidebar-spacer': clickNotClose}"
         class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">

      <div
        class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">

        <!-- TOGGLE SIDEBAR BUTTON -->
        <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon"
                      @click.stop="toggleTodoSidebar(true)"/>

        <!--         SEARCH BAR-->
        <vs-input v-model="searchQuery" class="vs-input-no-border vs-input-no-shdow-focus w-full" icon="icon-search"
                  icon-no-border icon-pack="feather"
                  placeholder="Search..." size="large"/>
      </div>

      <!-- TODO LIST -->
      <component :is="scrollbarTag" :key="$vs.rtl" ref="taskListPS" :settings="settings"
                 class="todo-content-scroll-area">

        <transition-group appear class="todo-list pl-0" name="list-enter-up" tag="ul">
          <li
            v-for="(task, index) in taskList"
            :key="String(currFilter) + String(task.id) + String(task.updated_at)"
            :style="[{transitionDelay: (index * 0.1) + 's'}]"
            class="cursor-pointer todo_todo-item">

            <todo-task :taskId="task.id"
                       @showDisplayPrompt="showDisplayPrompt($event)"/>
          </li>
        </transition-group>
      </component>
      <!-- /TODO LIST -->

    </div>

    <!-- EDIT TODO DIALOG -->
    <todo-edit v-if="displayPrompt" :displayPrompt="displayPrompt" :taskId="taskIdToEdit"
               @hideDisplayPrompt="hidePrompt"></todo-edit>
  </div>
</template>

<script>
// import moduleTodo from '@/store/todo/module.js'
import module from '@/store/TaskManagement/module.js'
import TodoAddNew from './TodoAddNew.vue'
import TodoTask from './TodoTask.vue'
import TodoFilters from './TodoFilters.vue'
import TodoEdit from './TodoEdit.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  data () {
    return {
      currFilter: '',
      clickNotClose: true,
      displayPrompt: false,
      taskIdToEdit: 0,
      isSidebarActive: true,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      }
    }
  },
  watch: {
    todoFilter () {
      const scroll_el = this.$refs.taskListPS.$el || this.$refs.taskListPS
      scroll_el.scrollTop = 0

      this.toggleTodoSidebar()

      // Fetch Tasks
      const filter = this.$route.params.filter
      this.$store.dispatch('taskManagement/fetchTasks', {filter})
      this.$store.commit('taskManagement/UPDATE_TODO_FILTER', filter)
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    todo () {
      return this.$store.state.todo.todoArray
    },
    todoFilter () {
      return this.$route.params.filter
    },
    taskList () {
      return this.$store.getters['taskManagement/queriedTasks']
    },
    searchQuery: {
      get () {
        return this.$store.state.todo.todoSearchQuery
      },
      set (val) {
        this.$store.dispatch('taskManagement/setTodoSearchQuery', val)

        const filter = this.$route.params.filter
        this.$store.dispatch('taskManagement/fetchTasks', {filter})
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    showDisplayPrompt (itemId) {
      this.taskIdToEdit = itemId
      this.displayPrompt = true
    },
    hidePrompt () {
      this.displayPrompt = false
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarActive = this.clickNotClose = false
      } else {
        this.isSidebarActive = this.clickNotClose = true
      }
    },
    toggleTodoSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarActive = value
    }
  },
  components: {
    module,
    TodoAddNew,
    TodoTask,
    TodoFilters,
    TodoEdit,
    VuePerfectScrollbar
  },
  created () {
    this.$store.registerModule('todo', module)
    this.setSidebarWidth()

    const filter = this.$route.params.filter

    // Fetch Tasks
    this.$store.dispatch('taskManagement/fetchTasks', {filter})
    this.$store.commit('taskManagement/UPDATE_TODO_FILTER', filter)

    // Fetch Tags
    this.$store.dispatch('taskManagement/fetchTags')
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('todo')
  },
  mounted () {
    this.$store.dispatch('taskManagement/setTodoSearchQuery', '')
  }
}

</script>


<style lang="scss">
@import "src/assets/scss/vuexy/apps/todo";
</style>
