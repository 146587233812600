<template>
  <div class="todo__filters-container">

    <div class="px-6 pt-4">
      <h5 class="font-bold">Filtre</h5>
    </div>

    <vs-divider></vs-divider>

    <div class="px-6">
      <router-link :class="{'text-primary': todoFilter === 'all'}" :to="`${baseUrl}/all`" class="flex mt-6 cursor-pointer"
                   tag="span">
        <feather-icon :svgClasses="[{'text-primary stroke-current': todoFilter === 'all'}, 'h-6 w-6']"
                      icon="LayersIcon"></feather-icon>
        <span class="text-lg ml-3">Toate</span>
      </router-link>

      <template v-for="filter in todoFilters">
        <router-link :key="filter.filter" :class="{'text-primary': todoFilter === filter.filter}" :to="`${baseUrl}/${filter.filter}`"
                     class="flex mt-6 cursor-pointer" tag="span">
          <feather-icon :icon="filter.icon"
                        :svgClasses="[{'text-primary stroke-current': todoFilter === filter.filter}, 'h-6 w-6']"></feather-icon>
          <span class="text-lg ml-3">{{ filter.filterName }}</span>
        </router-link>
      </template>

    </div>

    <div class="px-6 pt-12">
      <h5 class="font-bold">Categorii</h5>
    </div>

    <vs-divider></vs-divider>

    <div class="px-6 pb-4">
      <div class="todo__lables-list">
        <router-link v-for="(tag, index) in taskTags" :key="index"
                     :to="`${baseUrl}/${tag.id}`" class="todo__label flex items-center mt-6 cursor-pointer" tag="span">
          <div :class="'border-2 border-solid border-' + tag.color" :style="'border-color:' + tag.color"
               class="ml-1 h-3 w-3 rounded-full mr-4"/>
          <span :class="{'text-primary': todoFilter === tag.id}" class="text-lg">{{ tag.name }}</span>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      todoFilters: [
        {filterName: 'Terminate', filter: 'completed', icon: 'CheckIcon'},
        {filterName: 'Depasite', filter: 'overdue', icon: 'InfoIcon'}
      ]
    }
  },
  computed: {
    taskTags () {
      return this.$store.state.todo.taskTags
    },
    todoFilter () {
      return this.$route.params.filter
    },
    baseUrl () {
      const path = this.$route.path
      return path.slice(0, path.lastIndexOf('/'))
    }
  }
}
</script>
